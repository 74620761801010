<template>
  <div class="page-car">
    <div class="scene">
      <div class="scene-inner">
        <h1 class="welcome">Thanks for scanning!</h1>
        <a href="/" class="btn-welcome btn">Go to main site</a>
        <div class="sky"></div>
        <div class="back" :style="city('back')"></div>
        <div class="middle" :style="city('mid')"></div>
        <div class="front" :style="city('front')"></div>
        <div :style="car" class="car">
          <div :style="alloy" class="alloy"></div>
          <div :style="alloy" class="alloy"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'car',

    computed: {
      city() {
        return (layer) => {
          const type = require('../assets/city-' + layer + '.svg')
          return `background-image: url('${type}')`
        }
      },

      alloy() {
        const alloy = require('../assets/alloy.png')
        return `background-image: url('${alloy}')`
      },

      car() {
        const car = require('../assets/range.png')
        return `background-image: url('${car}')`
      }
    }
  }
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/base/theme'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/helpers/extends'

  .wrapper-car
    .navigation, .footer
      display: none

  @keyframes scroll
    0%
      background-position: 0 100%
    100%
      background-position: -1024px 100%

  @keyframes zoom-out
    0%
      transform: scale(4.5)
    100%
      transform: scale(1)

  .scene
    height: 100vh
    position: relative
    overflow: hidden

  .welcome
    z-index: 10
    position: absolute
    top: 50px
    width: 100%
    text-align: center

  .btn-welcome
    opacity: 0
    display: block
    animation: fadeIn 2s 6s ease-in-out
    animation-fill-mode: forwards
    z-index: 10
    position: absolute
    top: 185px
    left: 50%
    transform: translateX(-50%)
    margin: auto
    background: rgba(0, 0, 0, 0.5)!important

  .scene-inner
    transform-origin: 47.5% 86%
    height: 100%
    position: relative
    animation: zoom-out 5s ease-in-out
    animation-fill-mode: forwards

  .sky
    background: linear-gradient(to bottom, #1afff4 0%, #8affc6 100%)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

    &:before
      content: ''
      background: grey
      position: absolute
      bottom: 0
      height: 200px
      width: 100%
      border-top: 5px solid #404040

    &:after
      content: ''
      background: #222222
      position: absolute
      bottom: 0
      height: 80px
      width: 100%

  .back, .middle, .front
    position: absolute
    left: 0
    bottom: 200px
    width: 100%
    background-size: auto 100%
    background-repeat: repeat-x
    background-position: 0 100%
    width: 100%

  .back
    z-index: 2
    animation: scroll 100s linear infinite
    height: 300px

  .middle
    z-index: 3
    animation: scroll 50s linear infinite
    height: 250px

  .front
    z-index: 3
    animation: scroll 30s linear infinite
    height: 150px

  .car
    width: 250px
    height: 80px
    background-size: 100% auto
    position: absolute
    bottom: 15%
    left: 50%
    transform: translateX(-50%)
    z-index: 5

    .alloy
      width: 42px
      height: 42px
      position: absolute
      bottom: -11px
      border: 6px solid #1c1e1b
      border-radius: 100%
      background-size: cover
      animation: spin 3s linear infinite

      &:first-child
        left: 25px

      &:last-child
        right: 30px
</style>
