/**
 * @returns {boolean} is app loading something from server
 */
export function loading(state) {
  return state.activeRequests > 0 && process.env.VUE_APP_TARGET === 'client'
}

export function currentPage(state, getters) {
  if(typeof state.route === 'undefined') return {}
  if(!state.route.path) return {}

  return state.data[getters.currentPageSlug] || {}
}

export function currentPageMeta(state, getters) {
  return getters.currentPage.meta || {}
}

export function currentPageSlug(state) {
  /*
   * Get page from route meta OR the :slug param
   */
  return state.route.meta.pageSlug || state.route.params.slug
}

export function host(state) {
  /*
   * Get the current host from entry server
   */
  return state.host
}
