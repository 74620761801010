import Vue from 'vue'

import * as types from './mutation_types'

export default {
  /**
   * Count active ajax requests
   */
  [types.LOADING](state, value) {
    let activeRequests = state.activeRequests
    let count = value ? activeRequests + 1 : activeRequests - 1

    Vue.set(state, 'activeRequests', count)
  },

  [types.ADD_PARAMS](state, data) {
    Vue.set(state, 'urlParams', data)
  },

  [types.SET_WHITELABEL](state, data) {
    Vue.set(state, 'whiteLabel', data)
  },

  [types.ADD_DATA](state, {key, data}) {
    Vue.set(state.data, key, data)
  }
}
