<template>
  <div class="article" v-if="!loading && hasContent">
    <script v-html="jsonld" type="application/ld+json"></script>
    <span itemscope itemtype="https://span.org/Thing">
      <Banner>
        <h1>{{articleTitle}}</h1>
      </Banner>

      <div class="container tiny">
        <RichText :content="content.items[0].fields.content"/>
        <div class="share-article">
          <p>Share Article:</p>
          <a
            :href="`https://facebook.com/sharer.php?u=${currentUrl}`"
            target="_blank"
            rel="noopener">
            <Sprite name="facebook"/>
          </a>
          <a
            :href="`https://twitter.com/share?url=${currentUrl}&text=${articleTitle}`"
            target="_blank"
            rel="noopener">
            <Sprite name="twitter"/>
          </a>
          <a
            :href="`https://linkedin.com/shareArticle?url=${currentUrl}&title=${articleTitle}`"
            target="_blank"
            rel="noopener">
            <Sprite name="linkedin"/>
          </a>
          <a
            :href="`https://reddit.com/submit?url=${currentUrl}&title=${articleTitle}`"
            target="_blank"
            rel="noopener">
            <Sprite name="reddit"/>
          </a>
          <a
            :href="`mailto:?&subject=${articleTitle}&body=Check this out: ${currentUrl}`"
            target="_blank"
            rel="noopener">
            <Sprite name="email"/>
          </a>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
const RichText = () => import('@/components/RichText')
const Banner = () => import('@/components/Banner')
const Sprite = () => import('@/components/Sprite')

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'blog-article',

  props: {
    slug: String
  },

  components: {
    RichText,
    Banner,
    Sprite
  },

  computed: {
    ...mapState(['data', 'whiteLabel']),
    ...mapGetters(['loading']),

    hasContent() {
      return this.content.total > 0
    },

    articleTitle() {
      return this.hasContent ? this.content.items[0].fields.title : ''
    },

    title() {
      return `${this.articleTitle} | ${this.whiteLabel ? 'NewWebsite' : 'PageMakerPro'}`
    },

    excerpt() {
      return this.hasContent ? this.content.items[0].fields.excerpt : 'Blog article'
    },

    currentUrl() {
      return typeof window !== 'undefined' ? window.location.href : ''
    },

    content() {
      return this.data[this.slug] || {}
    },

    articleImage() {
      return this.hasContent ? this.content.items[0].fields.image.fields.file.url : ''
    },

    meta() {
      return [
        {vmid: 'og:title', property: "og:title", content: this.articleTitle},
        {vmid: 'og:url', property: "og:url", content: this.currentUrl},
        {vmid: 'og:image', property: "og:image", content: this.articleImage}
      ]
    },

    jsonld() {
      return {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": this.currentUrl
        },
        "headline": this.articleTitle,
        "image": this.articleImage,
        "author": {
          "@type": "Organization",
          "name": "NewWebsite",
          "url": "https://new-website.co.uk"
        },
        "publisher": {
          "@type": "Organization",
          "name": "NewWebsite",
          "logo": {
            "@type": "ImageObject",
            "url": "https://i.postimg.cc/ydNH3Tfz/logo-copy-2.png"
          }
        },
        "datePublished": ""
      }
    }
  },

  methods: {
    ...mapActions(['getContentfulEntries']),

    getData() {
      /**
       * Get current content
       */
      // if (!this.data[this.slug]) {
        this.getContentfulEntries({type: 'blogPost', slug: this.slug})
      // }
    }
  },

  metaInfo() {
    return {
      title: this.title,
      description: this.excerpt,
      meta: this.meta || []
    }
  },

  serverPrefetch() {
    return this.getContentfulEntries({type: 'blogPost', slug: this.slug})
  },

  mounted() {
    this.getData()
  },

  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/base/theme'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/helpers/extends'

  .article
    .rich-text
      p, li p
        font-size: 18px

        &:first-child
          font-size: 20px

      ol
        list-style: none
        padding: 0

        li
          counter-increment: section
          position: relative
          padding-left: $gap-large

          &:before
            content: counter(section)
            position: absolute
            top: 0
            left: 0
            color: #fff
            font-family: $font-bold
            font-size: 24px
            text-align: center
            padding: 4px 8px
            color: rgba(0, 0, 0, 0.1)

  .share-article
    position: sticky
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    clip-path: polygon(5% 0, 95% 0, 100% 100%, 0% 100%)
    padding: $gap-x-small

    @include themify($themes)
      background: themed('color-primary')

    p
      display: none

      @include media($media-small)
        display: block
        position: absolute
        left: $gap-large
        font-family: $font-bold
        color: #fff!important

    a
      margin: 0 $gap-small

    .sprite
      width: 30px
      height: 30px
      fill: #fff
</style>