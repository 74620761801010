<template>
  <div class="blog-page" v-if="!loading && hasContent">
    <Banner :noSlant="true">
      <h1>Our Blog</h1>
    </Banner>

    <div class="container">
      <div class="blog-categories">
        <button
          class="btn"
          @click="setCategory(false)">
          All Articles
        </button>
        <button
          v-for="category in categories"
          class="btn"
          :class="{'secondary': categoryActive(category)}"
          :key="category"
          @click="setCategory(category)">
          {{category}}
        </button>
      </div>

      <transition-group name="articles" class="blog-articles">
        <article
          v-for="article in blogPosts.items"
          :key="article.fields.slug"
          v-if="!filterActive || categoryActive(article.fields.category)">
          <router-link
            class="article"
            :to="{name: 'blog-article', params: {slug: article.fields.slug}}">

            <LazyImage responsive :url="articleImage(article)"/>
            <span>{{article.fields.title}}</span>
          </router-link>
        </article>
      </transition-group>
    </div>
  </div>
</template>

<script>
const Banner = () => import('@/components/Banner')
const LazyImage = () => import('@/components/LazyImage')

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'blog',

  data() {
    return {
      activeCategory: '',
      filterActive: false
    }
  },

  components: {
    Banner,
    LazyImage
  },

  computed: {
    ...mapState(['data', 'whiteLabel']),
    ...mapGetters(['loading']),

    hasContent() {
      return this.blogPosts.total > 0
    },

    articleImage() {
      return (article) => {
        return article.fields.image
          ? article.fields.image.fields.file.url
          : require('../assets/article-placeholder.jpg')
      }
    },

    categoryActive() {
      return (category) => {
        return category === this.activeCategory
      }
    },

    categories() {
      let categories = []

      this.blogPosts.items.forEach((article) => {
        categories.push(article.fields.category)
      })

      let uniqueCategories = [...new Set(categories)]
      return uniqueCategories
    },

    blogPosts() {
      return this.data['blogPost'] || {}
    },

    title() {
      return `Insights | ${this.whiteLabel ? 'NewWebsite' : 'PageMakerPro'}`
    },

    meta() {
      return [
        {vmid: 'og:title', property: "og:title", content: this.title},
        {vmid: 'og:url', property: "og:url", content: 'https://new-website.co.uk/blog-123'},
        {vmid: 'og:image', property: "og:image", content: 'https://i.postimg.cc/Pr9gyctW/og.jpg'},
        {vmid: 'og:description', property: "og:description", content: 'this is our blog page'}
      ]
    }
  },

  methods: {
    ...mapActions(['getContentfulEntries']),

    getData() {
      /**
       * Get current content
       */
      // if (!this.data['blogPost']) {
        this.getContentfulEntries({type: 'blogPost', slug: false, limit: 100, onlyPublic: true})
      // }
    },

    setCategory(category) {
      if(category) {
        this.activeCategory = category
        this.filterActive = true
      } else {
        this.activeCategory = ''
        this.filterActive = false
      }
    },
  },

  metaInfo() {
    return {
      title: this.title,
      description: 'View the latest insights from us.',
      meta: this.meta || []
    }
  },

  serverPrefetch() {
    return this.getContentfulEntries({type: 'blogPost', slug: false, limit: 100, onlyPublic: true})
  },

  mounted() {
    this.getData()
  },

  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/helpers/extends'


  .blog-categories
    display: none

    @include media($media-medium)
      display block
      position: relative
      padding: $gap-x-small 0
      display: flex
      align-items: center
      justify-content: center
      max-width: 75%
      margin: -60px auto $gap-large
      clip-path: polygon(95% 0, 100% 50%, 95% 100%, 5% 100%, 0% 50%, 5% 0)
      background: $triangle-dark
      z-index: 10

    .btn
      margin: 0 $gap-medium
      z-index: 5

      &:after
        display: none

  .blog-articles
    display: flex
    justify-content: center
    flex-wrap: wrap
    padding: $gap-large $gap-medium

  .articles-enter
    transform: scale(0.5) translateY(-80px)
    opacity: 0

  .articles-leave-to
    transform: translateY(30px)
    opacity: 0

  .articles-leave-active
    position: absolute
    z-index: -1

  .blog-page
    article
      transition: all .3s ease-in-out
      padding: $gap-small
      width: calc(#{$max-width}/3 - 40px)

    .article
      @extend %card
      padding: 0
      width: 100%
      height: 100%
      display: flex
      flex-direction: column

    span
      padding: $gap-medium

    img
      height: 300px
      display: block
      object-fit: cover

    ul
      position: relative
      padding: 0
      display: flex
      align-items: center
</style>