import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from '@/store/getters'

import mutations from '@/store/mutations'
import state from '@/store/state'
import actions from '@/store/actions'

Vue.use(Vuex)

export function createStore() {
  /* elsint-disable no-new */
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    state
  })
}
