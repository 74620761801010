import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Page from './views/Page'
import Car from './views/Car'
import Article from './views/Article'
import Blog from './views/Blog'

Vue.use(Router)
Vue.use(VueMeta)

export function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: 'is-active',

    scrollBehavior(to) {
      if(!to.meta.preventScroll){
        return { x: 0, y: 0 }
      }
    },

    routes: [
      {
        path: '/car',
        name: 'car',
        component: Car
      },
      {
        path: '/',
        name: 'home',
        component: Page,
        meta: {
          slug: 'home'
        }
      },
      {
        path: '/blog',
        name: 'blog',
        component: Blog
      },
      {
        path: '/blog/:slug',
        name: 'blog-article',
        component: Article,
        props: true
      },
      {
        path: '/:service-for-:slug',
        name: 'service-landing',
        component: Page,
        props: true
      },
      {
        path: '/:service-in-:slug',
        name: 'location-landing',
        component: Page,
        props: true
      },
      {
        path: '/:slug',
        name: 'page',
        component: Page,
        props: true
      }
    ]
  })
}