<template>
  <div>
    <div v-if="!loading && hasContent">
      <span itemscope itemtype="https://span.org/Thing">
        <div
          v-for="(widget, index) in widgets"
          :key="index"
          :class="{'animate-trigger': index > 0}">
          <div
            ref="pageWidgets"
            :is="widget.fields.type"
            :index="index"
            :pageSlug="slug"
            :options="widget.fields.options"
            :heading="widgetHeading(widget.fields.heading)"
            :content="widget.fields.items">
          </div>
        </div>
      </span>
    </div>

    <div v-if="!loading && !hasContent">
      <h2>Not found</h2>
    </div>
  </div>
</template>

<script>
const Accordion = () => import('@/components/Accordion')
const Banner = () => import('@/components/Banner')
const Reviews = () => import('@/components/Reviews')
const TextBlocks = () => import('@/components/TextBlocks')
const FeatureBlocks = () => import('@/components/FeatureBlocks')
const PricingBlocks = () => import('@/components/PricingBlocks')
const PricingTable = () => import('@/components/PricingTable')
const Contact = () => import('@/components/Contact')

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'page',

  props: {
    service: String,
    slug: String
  },

  components: {
    Accordion,
    Banner,
    Reviews,
    TextBlocks,
    FeatureBlocks,
    PricingBlocks,
    PricingTable,
    Contact
  },

  computed: {
    ...mapState(['data', 'whiteLabel', 'site']),
    ...mapGetters(['loading']),

    pageSlug() {
      const hasService = this.service
      let slug

      if (hasService) {
        // Is a landing page
        slug = `${this.service}-landing`
      } else if (this.slug) {
        // Is a normal page
        slug = this.slug
      } else {
        // Is the home page
        slug = this.$route.meta.slug
      }

      return slug
    },

    pageTitle() {
      return `${this.title} | ${this.currentSite}`
    },

    hasContent() {
      return this.content.total > 0
    },

    currentSite() {
      return this.hasContent ? this.content.items[0].fields.site : ''
    },

    widgets() {
      return this.content.items[0].fields.content || []
    },

    title() {
      if (this.service) {
        const connector = this.$route.name === 'service-landing' ? 'for' : 'in'
        let service = this.service.charAt(0).toUpperCase() + this.service.substring(1).replace(/-/g, ' ')
        let slug = this.slug.charAt(0).toUpperCase() + this.slug.substring(1).replace(/-/g, ' ')

        return `${service} ${connector} ${slug}`
      } else {
        const hasSeoTitle = this.hasContent && this.content.items[0].fields.seoTitle
        if (hasSeoTitle) {
          return this.content.items[0].fields.seoTitle
        } else {
          return this.hasContent ? this.content.items[0].fields.title : ''
        }
      }
    },

    widgetHeading() {
      return (heading) => {
        if (heading) {
          let text = heading.split(' ')
          let last = text.pop()
          return text.join(' ') + (text.length > 0 ? ' <span class="last-word">'+last+'</span>' : last)
        }

        return false
      }
    },

    content() {
      return this.data[this.pageSlug] || {}
    },

    currentUrl() {
      return typeof window !== 'undefined' ? window.location.href : ''
    },

    pageImage() {
      return this.hasContent ? this.content.items[0].fields.seoImage.fields.file.url : ''
    },

    noIndexMeta() {
      const host = typeof window !== 'undefined' ? window.location.href : ''
      return host.includes('staging') ? {name: 'robots', content: 'noindex'} : {}
    },

    metaDescription() {
      return this.hasContent ? this.content.items[0].fields.seoDescription : ''
    },

    faviconPath() {
      const site = this.whiteLabel ? 'nw' : 'pmp'
      return require(`../assets/favicon-${site}.png`)
    },

    meta() {
      return [
        {vmid: 'og:title', property: "og:title", content: this.pageTitle},
        {vmid: 'og:url', property: "og:url", content: this.currentUrl},
        {vmid: 'og:image', property: "og:image", content: this.pageImage},
        {vmid: 'og:description', property: "og:description", content: this.metaDescription},
        {rel: 'shortcut icon', href: this.faviconPath},
        this.noIndexMeta
      ]
    }
  },

  methods: {
    ...mapActions(['getContentfulEntries', 'getContentfulAsset']),

    getData() {
      /**
       * Get current content
       */
      // if (!this.data[this.pageSlug]) {
        this.getContentfulEntries({type: 'page', slug: this.pageSlug, limit: 1000, onlyPublic: null})
      // }

      if (this.service && !this.data['asset-' + this.slug]) {
        this.getContentfulAsset({title: this.slug})
      }
    },

    sectionInView() {
      window.setTimeout(() => {
        let callback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('has-entered')
            }
          })
        }

        let observer = new IntersectionObserver(callback, {
          threshold: [0]
        })

        const divs = document.querySelectorAll('.animate-trigger')

        divs.forEach((div) => {
          if (div) observer.observe(div)
        })
      }, 1000)
    }
  },

  metaInfo() {
    return {
      title: this.pageTitle,
      description: this.metaDescription,
      meta: this.meta || []
    }
  },

  serverPrefetch() {
    return this.getContentfulEntries({type: 'page', slug: this.pageSlug, limit: 1000, onlyPublic: null})
  },

  mounted() {
    this.getData()
    this.sectionInView()
  },

  watch: {
    $route() {
      this.getData()
      this.sectionInView()
    }
  }
}
</script>

<style lang="sass">
  .animate-trigger
    opacity: 0
    transform: translateY(100px)
    transition: all .5s ease-in-out

    &.has-entered
      transform: translateY(0)
      opacity: 1
</style>