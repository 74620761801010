import * as types from '@/store/mutation_types'

const contentful = require('contentful')

const client = contentful.createClient({
  space: "2a7soiyo67aa",
  accessToken: "vn1I76A173QPcPyMS3gUwvvlHb-RxhhjLsvqXRTQwkg"
})

export default {
  getContentfulEntries({commit}, {type, slug, limit, onlyPublic}) {
    commit(types.LOADING, true)

    let host = this.getters.host
    let key = type

    let params = {
      content_type: type,
      limit: limit ? limit : 100,
      include: 10
    }

    if(host.includes('new-website')) {
      params['fields.site'] = 'NewWebsite'
    } else {
      params['fields.site'] = 'PageMakerPro'
    }

    if(slug) {
      key = slug
      params['fields.slug'] = slug
    }

    if(onlyPublic) {
      params['fields.visibility'] = 'Public'
    }

    client
      .getEntries(params)

      .then(entry => {
        commit(types.ADD_DATA, {key, data: entry})
      })

      .catch(err => console.warn(err)

      .finally(() => {
        commit(types.LOADING, false)
      }))
  },

  getContentfulAsset({commit}, {title}) {
    commit(types.LOADING, true)

    let key = `asset-${title}`

    client
      .getAssets({
        'fields.title': title
      })

      .then(asset => {
        commit(types.ADD_DATA, {key, data: asset.items[0]})
      })
      .catch(function (e) {
        console.log(e)
      })

      .finally(() => {
        commit(types.LOADING, false)
      })
  }
}
