<template>
  <div id="app" :class="[
    whiteLabel ? 'theme-new-website' : 'theme-default',
    wrapperClass,
    {'has-scrolled': navScrolled}
    ]">

    <div class="loading" v-if="loading || !loaded">
      <div class="loading-icon">
        <Sprite name="gear"/>
      </div>
    </div>

    <Navigation
      v-if="loaded"
      :navScrolled="navScrolled"
      :whiteLabel="whiteLabel"/>

    <main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"/>
      </transition>
    </main>

    <transition name="fade" mode="out-in">
      <div class="cookie-bar" v-if="cookieActive">
          <p>This website uses cookies to ensure you get the best experience possible. Your continued use of this site is taken as agreement with our use of cookies.</p>
        <button class="btn secondary" @click="closeCookieBar">Okay, got it</button>
      </div>
    </transition>

    <Footer v-if="loaded" :whiteLabel="whiteLabel"/>
  </div>
</template>

<script>
import * as types from '@/store/mutation_types'
import {mapGetters} from 'vuex'

const Navigation = () => import('@/components/Navigation')
const Footer = () => import('@/components/Footer')
const Sprite = () => import('@/components/Sprite')

import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

export default {
  data() {
    return {
      theme: '',
      whiteLabel: '',
      loaded: false,
      navScrolled: false,
      cookieActive: false
    }
  },

  components: {
    Navigation,
    Footer,
    Sprite
  },

  mounted() {
    this.$store.state.host = window.location.host
    this.setTheme()
    this.initGoogleAnalytics()
    this.handleScroll()
    this.setParams()
  },

  methods: {
    setParams() {
      this.$store.commit(types.ADD_PARAMS, this.urlParams)
    },

    setNavClass() {
      this.navScrolled = window.scrollY > 100
    },

    handleScroll() {
      window.addEventListener('scroll', this.throttle(this.setNavClass, 15))
    },

    throttle(fn, wait) {
      let time = Date.now()

      return () => {
        if((time + wait - Date.now()) < 0 ) {
          fn()
          time = Date.now()
        }
      }
    },

    setTheme() {
      const whiteLabel = 'new-website'
      this.whiteLabel = window.location.host.includes(whiteLabel)
      document.body.classList.add('is-whitelabelled')
      this.$store.commit(types.SET_WHITELABEL, this.whiteLabel)
      this.loaded = true
    },

    initGoogleAnalytics() {
      const prod = process.env.NODE_ENV === 'production'

      Vue.use(VueAnalytics, {
        debug: {
          enabled: !prod,
          sendHitTask: prod
        },

        router: this.$router,
        id: process.env.VUE_APP_ANALYTICS_ID

      })
    },

    closeCookieBar() {
      this.cookieActive = false
    }
  },

  computed: {
    ...mapGetters(['loading']),

    urlParams() {
      return this.$route.query
    },

    wrapperClass() {
      return `wrapper-${this.$route.name}`
    }
  }
}
</script>

<style lang="sass">
@import 'stylesheets/base/variables'
@import 'stylesheets/base/typography'
@import 'stylesheets/base/animations'
@import 'stylesheets/helpers/mixins'
@import 'stylesheets/helpers/extends'
@import 'stylesheets/buttons'
@import 'stylesheets/base/theme'

*
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  margin: 0
  overflow-x: hidden

a
  text-decoration: none

img
  user-drag: none
  user-select: none
  -moz-user-select: none
  -webkit-user-drag: none
  -webkit-user-select: none
  -ms-user-select: none

#app
  font-family: $font-body
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smooxthing: grayscale
  display: flex
  flex-direction: column

  @include themify($themes)
    color: themed('color-body')

main

.loading
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  background: rgba(255, 255, 255, 0.85)
  z-index: 10

  .loading-icon
    .sprite
      animation: spin 1.5s infinite linear
      width: 50px
      height: 50px

      @include themify($themes)
        fill: themed('color-secondary')

.fade-enter-active, .fade-leave-active
  transition: opacity .3s ease

.fade-enter, .fade-leave-to
  opacity: 0

.container
  width: 100%
  max-width: $max-width
  margin: auto
  padding: $gap-x-small

  @include media($media-small)
    padding: $gap-medium

  &.small
    max-width: $max-width-small

  &.tiny
    max-width: $max-width-tiny

hr
  margin: $gap-large
  border: 0
  background: rgba(0, 0, 0, 0.1)
  width: 100%
  height: 1px

.cookie-bar
  position: fixed
  bottom: $gap-small
  left: 50%
  transform: translateX(-50%)
  width: 90%
  max-width: 530px
  box-shadow: $box-shadow
  border-radius: 5px
  background: rgba(255, 255, 255, 0.95)
  padding: $gap-x-small
  text-align: center
  z-index: 10

  .btn
    font-size: 12px
    padding: 5px 8px
    margin-top: $gap-x-small

  p
    font-size: 11px
    line-height: 1.5
    margin: 0

.last-word
  @include themify($themes)
    color: themed('color-primary')

.section-shape
  background: #fff
  width: 100%
  height: 50px
  position: absolute
  bottom: -1px
  left: 0
  clip-path: polygon(100% 0, 100% 100%, 0 100%)

.wrapper-location-landing
  .param
    text-transform: capitalize

.mobile-only
  @include media($media-medium)
    display: none!important

.desktop-only
  display: none

  @include media($media-medium)
    display: block
</style>
